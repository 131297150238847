import "./ProductBanner.css";
import React from "react";
import {Card} from 'antd';
import { Link } from 'react-router-dom';

function template() {
  
  console.log("/"+this.props.productUrl)
  return (
    <div className="product-banner">
      <Link to={this.props.productUrl}>
        <Card  style={{ width: '100%' }} bodyStyle={{padding: "0"}} hoverable="true">
        <img src={require("../../../images/"+this.props.bannerSrc)} width="100%"></img>
        </Card>
      </Link>
    </div>
  );
};

export default template;
