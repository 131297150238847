import React    from "react";
import template from "./Equipments.jsx";

class Equipments extends React.Component {
  render() {
    return template.call(this);
  }
}

export default Equipments;
