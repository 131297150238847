import React   from "react";
import template from "./ControlledCarousel.jsx";

class ControlledCarousel extends React.Component {
  constructor(props){
    super(props); 
    this.state={
      index:0
    }
  }
  handleSelect = (selectedIndex, e) => {
    this.setState({index:selectedIndex});
  };
  render() {
    return template.call(this);
  }
}

export default ControlledCarousel;
