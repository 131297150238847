import "./ContactUs.css";
import React, { useState } from "react";
import HeadTitle from "../util/HeadTitle";
import { Input, Image, Form,Alert } from 'antd';
import { Button } from 'antd';
import emailjs from '@emailjs/browser';
const { TextArea } = Input;

function Template() {
  const [submitState, setSubmitState] = useState('NOTHING');
  const onFinish = (values) => {
    console.log(values);
    emailjs.send('service_wccg4mo', 'template_2su4wgk', values, 'vgDVHJG1BTDWQIQWV')
    .then((result) => {
      setSubmitState('SUCCEED');
    }, (error) => {
      setSubmitState('FAIL');
    });  };
  return (
    <div className="contact-us">

      <HeadTitle title="Contact Us" size="20px"></HeadTitle>
      迪高建材國際有限公司<br />
      Deko Building Materials International Limited<br />
      <br />
      <p>香港荔枝角長順街18號華盛工業大廈6樓B座601室</p>
      <p>Room 601, Unit B, Wah Shing Industrial Building, 18 Cheung Shun Street, Lai Chi Kok, Kowloon, Hong Kong</p>
      <p>Tel. : (852) 2838 3023</p>
      <p>Fax : (852) 2838 6370</p>
      <br />

      <iframe
        width="100%"
        height="450"
        style={{ border: 0, display: 'block' }}
        loading="lazy"
        allowfullscreen
        referrerpolicy="no-referrer-when-downgrade"
        src="https://www.google.com/maps/embed/v1/place?key=AIzaSyCEy_1DwtNovXsnrO_X47FjfYG6D-XoW88
    &q=Wah+Shing+Industrial+Building,+Cheung+Shun+St,+Lai+Chi+Kok">
      </iframe>
      <br />
      <br />
      <p>中國工廠:廣州市增城開發區永寧街朱寧路36號 </p>
      <p>No.36, Zhu Ning Road, Yong Ning Street, Zeng Cheng Development District, Guang Zhou, China</p>
      <p>Tel. : (86) 20 8296 6818 </p>
      <p>Fax : (86) 20 8296 1318 </p>
      <p>Website : <a href="http://www.deko-cn.com">http://www.deko-cn.com</a></p>
      <br />
      <iframe
        width="100%"
        height="450"
        style={{ border: 0, display: "block" }}
        loading="lazy"
        allowfullscreen
        referrerpolicy="no-referrer-when-downgrade"
        src="https://www.google.com/maps/embed/v1/place?key=AIzaSyCEy_1DwtNovXsnrO_X47FjfYG6D-XoW88
      &q=China,+Guang+Dong+Sheng,+Guang+Zhou+Shi,+Zeng+Cheng+Qu,+朱宁路36号">
      </iframe>
      <br />
      <div className="borderBox">
        <p>Enquiry Form</p>
        <Form  onFinish={onFinish}>
          <Form.Item  style={{ marginBottom: "0px" }} label="" name="from_name" rules={[{ required: true,message: 'Please input your User Name!', }, ]}>
          <Input className="messageBox" type="text" placeholder="Name" />
          </Form.Item>
          <Form.Item  style={{ marginBottom: "0px" }}  label="" name="from_company" rules={[{ required: true,message: 'Please input your Company!', }, ]}>
          <Input className="messageBox" type="text" id="FormCompany" name="from_company" placeholder="Company" />
          </Form.Item>
          <Form.Item style={{ marginBottom: "0px" }}  label="" name="telephone" rules={[{ required: true,message: 'Please input your Telephone!', }, ]}>
          <Input className="messageBox"  id="FormTelephone" name="telephone" placeholder="Telephone" />
          </Form.Item>
          <Form.Item style={{ marginBottom: "0px" }}  label="" name="from_email" rules={[{ required: true,message: 'Please input your Email!', }, ]}>
          <Input className="messageBox" type="text" id="FormEmail" name="from_email" placeholder="Email" />
          </Form.Item>
          <Form.Item style={{ marginBottom: "0px" }}  label="" name="message" rules={[{ required: true,message: 'Please input your Message!', }, ]}>
          <TextArea  className="messageBox"  rows={4} placeholder="Message" /> 
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 0, span: 16, }}>
          <Button type="primary"  htmlType="submit" >Submit</Button>
          </Form.Item>
        </Form>
        { (submitState=='SUCCEED')? 
            <Alert message="Submission Succeed"></Alert> :     
          (submitState=='FAIL')?  <Alert message="Submission Failure. Please Try Again."></Alert> : <></>
        }
      </div>
      <br/>
      <br/>
      <p>Get in touch </p>
      <p>For any enquiries, please feel free to contact our Marketing Manager</p>
      <p>Jennifer Ma 馬展文</p>
      <p>Mobile : (852) 9787 0211</p>
      <p>Email : jenniferma@dekohk.com.hk, jenniferma3@gmail.com</p>
      <a href="https://wa.me/85297870211">
      <img width="30px" style={{margin:'5px'}} src={require('../../images/whatsapp.jpg')} >
      </img>
      </a>
    </div >
  );
};

export default Template;
