import "./Honors.css";
import { Image } from 'antd';
import React from "react";
import DekoHonors from '../../images/DekoHonors.jpg';
import HeadTitle from '../util/HeadTitle';
function template() {
  return (
    <div className="honors">
      <HeadTitle title="Honors" size="20px"></HeadTitle>
      <div style={{width:'70%',margin:'auto'}}>
      <Image src={DekoHonors} ></Image>
      </div>
    </div>
  );
};

export default template;
