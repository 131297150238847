import React    from "react";
import template from "./Products.jsx";

class Products extends React.Component {


  generateDekoBanner(number){
    return {name:`Deko${number}_banner`,link:`deko${number}`};
  }
  render() {
    return template.call(this);
  }
}

export default Products;
