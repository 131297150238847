import React from 'react';
import ReactDOM from 'react-dom/client';
// import './index.css';
import "antd/dist/antd.min.css";
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter as Router
} from '../node_modules/react-router-dom/index';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router>
    <App />
  </Router>
);

// reportWebVitals();
