import React from 'react';
// @ts-ignore
import MainPage from './components/MainPage';

class App extends React.Component {

  constructor(props) {
    super(props);

  }
  render() {
    return (
      <MainPage></MainPage>
    );

  }



}



export default App;