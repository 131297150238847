import "./TestReport.css";
import React from "react";
import ControlledCarousel from '../util/ControlledCarousel'
import HeadTitle from "../util/HeadTitle";
function template() {
  return (
    <div className="test-report">
      <HeadTitle title="Test Report" size="20px"></HeadTitle> 
      <ControlledCarousel images={['AcousticTestReport(1).jpg','AcousticTestReport(2).jpg']}>
      </ControlledCarousel>
    </div>
  );
};

export default template;
