import "./MainPage.css";
import { Button, Layout, Menu } from 'antd';
import {LeftOutlined,DownOutlined} from '@ant-design/icons';
import React from 'react';
import AboutDeko from '../AboutDeko'
import ContactUs from '../ContactUs'
import Honors from '../Honors';
import ProjectReference from '../ProjectReference';
import TestReport from '../TestReport';
import Products from '../Products';
import ProductPage from '../util/ProductPage';
import ImageSlider from "../ImageSlider";

import {
  Routes,
  Link,
  Route,
  Outlet
} from "react-router-dom";

import Equipments from "../Equipments/Equipments";
const { Header, Content, Footer } = Layout;

function template() {
  const layoutwidth = this.state.width;
  const displayVertical = {
      display: "block",
      position: "absolute",
      left: "0%",
      top:"100%",
      width: "100%",
      "z-index": "1"
  }
  const displayHorizontal = {
    display: "block",
    position: "absolute",
    left: "-100%",
    top:"0",
    width: "100%",
    "z-index": "1"
  }
  let navMenu = this.navItems.map((element, index) => {
    if (element.subMenu != null) {
      return <li style={{float:"left", display: (layoutwidth>1200)?'inline-block':'inline',"min-width":(layoutwidth>1200)? '100px' : '100%' }}><ul key={index}  style={{ width:(layoutwidth>=1200)? '100px':'100%'}}>
        <li  className="navLink menuNav" style={{ width:'100%' }}             
          onMouseLeave={()=>{this.setState({selectedHome:false})}}
          onMouseEnter={()=>{this.setState({selectedHome:true})}}
        >
        {

          (layoutwidth>1200)?  <DownOutlined  className="outlined" style={{position:"absolute",top:"0px",height:"100%",left:"0px",padding:"26px 13px"}}/>  :<LeftOutlined className="outlined" style={{position:"absolute",top:"0px",height:"100%",left:"0px",padding:"26px"}}/>

        }
     
        <a href="#" style={{"font-style":"none", color:"black"}} onClick={()=>{this.setState({selectedHome:true})}}>  {element.label}</a> 
           {
          element.subMenu.map((element1) =>
          (
            <li className="navLink" key={index} style={
                (this.state.selectedHome)?
                (layoutwidth>1200)?
                  displayVertical
                :
                  displayHorizontal
                :
                {
                  display:"none"
                }



            } onClick={(element1) => { this.setState({ lastSelectedId: element.key }); console.log(element1.key) }}
            >
              <Link className="navLinkText" to={element1.link}>
                {element1.label}</Link>
            </li>

          )
          )
        }
        </li>

      </ul>
      </li>
    }
    else
      return <li className="navLink" key={index} style={{float:"left", display: (layoutwidth>1200)?'inline-block':'inline',"min-width":(layoutwidth>1200)? '100px' : '100%' }} onClick={(element) => { this.setState({ lastSelectedId: element.key });this.setState({selectedHome:false})}} ><Link  className="navLinkText" style={{ display: 'inline-block' }} to={element.link}>{element.label}</Link></li>
  });
  let productPage =
    this.productPages.map((element) => {
      return (<Route path={`/products/${element.link}`}
        element={<ProductPage title={element.title} folderName={element.folderName} productImages={this.productPageByDirectory(element.folderName)}></ProductPage>} />)
    })
  let menu =
    (
      <ul style={{width:(layoutwidth>1200)? '730px':'140px'}}
      onMouseEnter={()=>{this.setState({enterMenu:true})}}
      onMouseLeave={()=>{this.setState({enterMenu:false})}}
      >{
        navMenu
      }
      </ul>
    );

  return (
    <Layout style={{ padding: "0px", width: '100%' }}>

      <Header className="white-header" style={{ float: "right", width:"100%",padding:'0px' }}>
        <div style={{
          margin: 'auto',
          width: (layoutwidth > 1200) ? '1200px' : '100%'
        }}>
          <div className="logo" style={{ margin: "0px 0px" }}>
            <img src={require('../../images/dekologo.png')} />
          </div>
          {
            (this.state.width > 1200) ? (<></>) :
              ((!this.state.showDropMenu) ? (<Button style={{ float: 'right', margin: "20px" }}
                onClick={() => { this.setState({ showDropMenu: !this.state.showDropMenu }) }}>Menu</Button>) : <></>)
          }
          {
            (this.state.showDropMenu & this.state.width < 1200) ? menu : (this.state.width >= 1200) ? menu : <></>
          }
        </div>


      </Header>
      <Content
        style={{
          width: '100%',
          margin: 'auto'
        }}
      >
        <Layout
          style={{
            margin: 'auto',
            width: (layoutwidth > 1200) ? '1200px' : '100%'
          }}
        >
          <Layout
            className="site-layout-background"
            style={{
              margin: 'auto',
              width: '100%'
            }}
          >
            <Routes>
              <Route path="" element={<ImageSlider />}>
              </Route>
            </Routes>
            <Content
              style={{
                width: '100%',
                padding: '1% 1%',

              }}>
              <Outlet />
              <Routes>
                <Route index path="" element={<AboutDeko />}>
                </Route>
                <Route path="contactUs" element={<ContactUs />}>
                </Route>
                <Route path="products" element={<Products />}>
                </Route>
                <Route path="equipments" element={<Equipments />}>
                </Route>
                <Route path="dekohonors" element={<Honors />}>
                </Route>
                <Route path="projectReference" element={<ProjectReference />}>
                </Route>
                <Route path="testReport" element={<TestReport />}>
                </Route>
                {
                  productPage
                }
              </Routes>
            </Content>

          </Layout>
        </Layout>
      </Content>
      <Footer
        style={{
          textAlign: 'center',
        }}
      >
        CopyRight @ 2022 Deko Architectural Product. All Rights Reserved
      </Footer>
    </Layout >);
};

export default template;
