import React    from "react";
import template from "./AboutDeko.jsx";

class AboutDeko extends React.Component {
  render() {
    return template.call(this);
  }
}

export default AboutDeko;
