import React    from "react";
import template from "./ProductBanner.jsx";

class ProductBanner extends React.Component {
  constructor(props){
    super(props);
  }

  render() {
    return template.call(this);
  }
}

export default ProductBanner;
