import "./ProjectReference.css";
import React from "react";
import ControlledCarousel from '../util/ControlledCarousel';
import ProjectRef1 from '../../images/ProjectReference1A.jpg';
import ProjectRef2 from '../../images/ProjectReference2A.jpg';
import ProjectRef3 from '../../images/ProjectReference3A.jpg';
import Carousel from 'react-bootstrap/Carousel';
import HeadTitle from "../util/HeadTitle";

function template() {

  return (
    <div className="project-reference">
    <HeadTitle title="Project Reference" size="20px"></HeadTitle> 
    <ControlledCarousel images={['ProjectReference1A.jpg','ProjectReference3A.jpg','ProjectReference2A.jpg','video.mp4']}>
    </ControlledCarousel>

    </div>
  );
};

export default template;
