import "./AboutDeko.css";
import React from "react";
import AboutDekoImage from '../../images/AboutDeko.jpg';
import AboutDekoImage3 from "../../images/about3.jpg";
import ImageSlider from "../ImageSlider";
function template() {
  return (

    <div className="about-deko">
      <br/>
      <h1 style={{"font-family":"'Merriweather', serif",'font-size': '20px'}}>About Us</h1>
      <br/>
      <img src={AboutDekoImage} style={{ width: '100%'}} ></img>
      <div className="main-text">
<br/>
Originally from Germany, the name DEKO is synonymous with the manufacturer and development of quality metal ceilings and aluminium curtain
walling. In the early 1990’s our Asian-Pacific headquarters, DEKO Building Materials Ltd. was founded in Hong Kong and through our manufacturing
facilities in Guangzhou now supplies products throughout the PRC via its business branches in Shanghai, beijing and Hong Kong.
<br/>
<br/>
Our company culture is to “strengthen and keep improving”. In order to do this DEKO responds to market forces by providing timely and cost effective
solutions to our customers requirements. Our commitment to this philosophy can be clearly seen with our recent investment in new manufacturing
facilities in the Ningxi Industrial Zone in Guangzhou, which now cover 50,000 square metres and an annual production capacity of over 1,500,000
square metres.
<br/>
<br/>
Producing quality products requires quality machinery. therefore many of our more advanced machines are imported from Germany with GEMA and
Randburg spraying equipment from Switzerland and Japan. Our commitment to quality is evident in our attaining ISO9001:2000 and ISO14001
certification, issued by quality experts SGS. In addition Deko has received a “Green indoor decoration material” certificate, and has been praised by
customers for “providing quality goods and customer service”and also received a commendation from the China Building Material enterprise management association. We are therefore confident that our design team will supply the best project for you!
<br/>
<br/>
Deko metal ceilings and curtain wall systems have been used in airports, stations, hospitals, exhibition centers and government buildings. Some of our
more pretigious projects are as follows; Hong Kong International Airport, Hong Kong Railway, Sha Tin Jockey Club in Hong Kong; International Airport,
Tianjin light rail, Mongolia International Airport, Zhuhai International Airport, Haiding People Hospital, Beijing Children’s Hospital, The Supreme Court of
The People’s Republic of Beijing, Hangzhou daily News Building, Shanghai Wandu Centre, Shanghai Eastern Air, Guangzhou Telecom, Guangzhou gymnasium,, Chengdu Unicom Building, Vietnam Hanoi Hotel, Johannisbury Airport, Tanger Airport, America West Asia Project of Skytrain (Acoustic Panel)
and The Supreme Court of The Republic Of Singapore. Deko are also on the Hong Kong Governments list of approved suppliers of building materials
(metal ceilings).
<br/>
<br/>

Deko Building Materials Limited (Guangzhou) has a worldwide reputation for good quality and good service. We keep on striving to improve customer
technical services and product quality. We have complete confidence, therefore, that through our dedication we can better serve our society.
</div>
      <img src={AboutDekoImage3} align="left" HSPACE="20" VSPACE="10" style={{width:'70%'}}></img>

</div>


  );
};

export default template;
