import React   from "react";
import Template from "./ContactUs.jsx";


class ContactUs extends React.Component {
  render() {
    return <Template/>
  }
}

export default ContactUs;
