import "./Products.css";
import React from "react";
import HeadTitle from "../util/HeadTitle";
import ProductBanner from '../util/ProductBanner';
import { Col, Row } from 'antd';
const style = {
  background: '#0092ff',
  padding: '8px 0',
};

function template() {
  let resultBanners = [];
  let dekoIdRange = [100, 1400, 100];

  for (let i = dekoIdRange[0]; i <= dekoIdRange[1]; i += dekoIdRange[2]) {
    resultBanners.push(this.generateDekoBanner(i));
  }
  let dekoOtherId = [1600, 1800, 2000, 3000, 4000, 5000];
  resultBanners = [...resultBanners,
  ...dekoOtherId.map((element) => this.generateDekoBanner(element)),
  ]
  let dekoOtherBanner = [
    {name:'Carriers_Components_banner',link:'Carriers_Components'},
     {name:'PerforationPattern_banner',link:'Perforation_Pattern'}];
  resultBanners = [
    ...resultBanners,
    ...dekoOtherBanner
  ]
  console.log(resultBanners);
  return (
    <div className="products">
      <HeadTitle title="Products" size="20px"></HeadTitle>
      <Row gutter={[16, 24]}>
        {
         
          resultBanners.map((element)=>{
            console.log("link",element.link)
          return <Col className="gutter-row" span={6}>
          <ProductBanner bannerSrc={(element.name+".jpg")} productUrl={element.link}></ProductBanner>
          </Col>
        })
        }
      </Row>
    </div>
  );
};

export default template;
