import React    from "react";
import template from "./ImageSlider.jsx";

class ImageSlider extends React.Component {
  constructor(props){
    super(props);
  
  }
  
  onChange = (currentSlide) => {
    console.log(currentSlide);
  };
  render() {
    return template.call(this);
  }
}

export default ImageSlider;
