import "./ProductPage.css";
import React from "react";
import ControlledCarousel from '../ControlledCarousel';
import HeadTitle from "../HeadTitle";
function template() {
  return (
    <div className="product-page">
      <HeadTitle title={this.props.title} size="20px"></HeadTitle>
      <ControlledCarousel images={this.props.productImages}></ControlledCarousel>
    </div>
  );
};

export default template;
