import React    from "react";
import template from "./ProjectReference.jsx";

class ProjectReference extends React.Component {
 
  render() {
    return template.call(this);
  }
}

export default ProjectReference;
