import "./HeadTitle.css";
import React from "react";

function template() {
  return (
    <div className="head-title">
        <br/>
        <h1 style={{"font-family":"'Merriweather', serif",'font-size': `${this.props.size}`}}>{this.props.title}</h1>
        <div style={{width:"100%", height:"1px" , background:'rgb(240,240,240)'}}></div>
        <br/>
        <br/>

    </div>
  );
};

export default template;
