import React    from "react";
import template from "./MainPage.jsx";
import  { useState} from 'react'

class MainPage extends React.Component {

  importAll(r) {
    this.allImagesPath = (r.keys());
    return r;
  }

  constructor(props){
    super(props);  

    this.onSelectNav.bind(this);
    this.allImagesPath = ['dd']
    this.pageName = '';
    this.navItems =  [
      {link:"home",label:"Home",
      subMenu:[
      {link:"",label:"About Deko"}
      ]
      },
      {link:"contactUs",label:"Contact Us"},
      {link:"products",label:"Products"},
      {link:"equipments",label:"Equipments"},
      {link:"dekohonors",label:"Honors"},
      {link:"projectReference",label:"Project Reference"},
      {link:"testReport",label:"Test Report"}
    ];
    this.productPages = this.initProductPageInfo();
    this.importAll(require.context("../../images", true, /^.*/));
    console.log('allImagesPath',this.allImagesPath);
    this.state = {selectedNav:[],lastSelectedId:'0',width:window.innerWidth,height:window.innerHeight,showDropMenu:false,enterMenu:true, selectedHome:false};
    window.addEventListener('resize', this.updateSize.bind(this));
    window.addEventListener('mousedown', this.showButton.bind(this));
    this.updateSize();

  }
  showButton(){
    if(this.state.showDropMenu&&!this.state.enterMenu){
      this.setState({showDropMenu:!this.state.showDropMenu});
    }
  }
  updateSize() {
    this.setState({width:window.innerWidth});
    this.setState({height:window.innerHeight});
  }
   
  initProductPageInfo(){
  let dekoRange = [100,1400,100];
  let dekoOtherId = [1600, 1800, 2000, 3000, 4000, 5000];
  let result = []
    for(let i=dekoRange[0];i<=dekoRange[1];i+=dekoRange[2]){
      result = [... result, { title:`Deko ${i}`,folderName:`Deko${i}`, link: `deko${i}`}];
    }
    result = [... result, ... dekoOtherId.map((element)=>({
      title:`Deko ${element}`,folderName:`Deko${element}`, link: `deko${element}`}))
    ];
    result = [...result,
    { title:"Carriers and Components",folderName:'Carriersandcomponents', link:'Carriers_Components'},
    {title:"Perforation Pattern",folderName:'PerforationPattern', link:'Perforation_Pattern'}
  ]
  return result;
  }
  onSelectNav(selectedKeys){  
  if(selectedKeys.length==0)
    return;
  let navName= this.navItems[selectedKeys[0]].label;
  this.pageName = navName;
  }
  productPageByDirectory(directory){
    const string = "^\./"+directory+"/.*";
    const regex = new RegExp(string);
     return this.allImagesPath.filter((element)=>{
      return regex.test(element);}).map((e)=>e.substring(2));
  }
  render() {
    console.log(this.state.width)
    return (template.call(this))
  }
}

export default MainPage;
