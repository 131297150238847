import React, { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { Image } from 'antd';


function template() {
  let carouselItems = this.props.images.map((element) => (
    <Carousel.Item>
      {

        (element.substring(element.length - 3, element.length) == "mp4") ?
        <video src={require('../../../images/video.mp4')} style={{width:"100%"}} autoPlay="true" controls></video >
          : <Image src={require('../../../images/' + element)}></Image>
      }
    </Carousel.Item>
  )
  );
  return (
      <Carousel style={{ width: '100%', color: 'black' }} activeIndex={this.index} onSelect={this.handleSelect}>
        {
          carouselItems
        }
      </Carousel>
  );
}

export default template;