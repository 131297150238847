import "./Equipments.css";
import React from "react";
import { Image } from 'antd';
import DekoEquipmentsJpg from '../../images/DekoEquipments.jpg';
import HeadTitle from "../util/HeadTitle";
function template() {
  return (
    <div className="equipments">
      <HeadTitle title="Equipments" size="20px"></HeadTitle> 
      <Image src={DekoEquipmentsJpg} style={{width:'100%'}}></Image>
    </div>
  );
};

export default template;
