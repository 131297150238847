import "./ImageSlider.css";
import React from "react";
import { Carousel } from 'antd';
import name1 from '../../images/KeyVisual1.jpg';
import ControlledCarousel from "../util/ControlledCarousel";
const images = require.context('../../images', true);

const contentStyle = {
  height: 'auto',
  width:'100%',
  color: '#fff',
  lineHeight: '160px',
  textAlign: 'center',
  background: '#364d79',
};
function template() {
  let visualSlide = ([1,2,3,4,5,6]).map((element)=>{ 
    const name =  'KeyVisual'+element+'.jpg';
    return name;
    let style={};
    Object.assign(style,contentStyle);
    return (<div ><img key={name} style={contentStyle} src={images(name)}></img></div>);
   });
  return (
  <ControlledCarousel style={{width:'100%', margin:'20px'}} images={visualSlide}></ControlledCarousel>
  );
};

export default template;
